.aviation {
    position: relative;
    margin-bottom: 10px;
    height: 700px;
}

.aviation-image {
    position: absolute;
    width: 100%;
    overflow: hidden;
}

.aviation-img {
    width: 100%;
    height: 700px;
    object-fit: cover;
    object-position: center; 
}

/* .aviation-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, hsl(0%, 0%, 0%)5%, hsla(0%, 0%, 0%, 0)40%, hsla(0%, 0%, 0%, 0)60%, hsl(0%, 0%, 0%)92%);
} */
.aviation-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgb(11,27,64) 5%, hsla(0, 0%, 0%, 0) 40%, hsla(0, 0%, 0%, 0) 60%,rgb(11,27,64) 92%);
}

.aviation-content {
    position: relative;
    padding-top: 30rem;
    text-align: center;
    row-gap: 2.5rem;
}