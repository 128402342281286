/* *{
    margin: 0;
    padding: 0;
    font-family: 'Bricolage Grotesque', sans-serif;
  }
   */
/* *{
    font-family: 'Bricolage Grotesque', sans-serif;
} */

@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@10..48,200;10..48,300;10..48,400;10..48,500;10..48,600;10..48,700;10..48,800&display=swap');

  .card-img-hd {
    height: 500px;
    width: 350px;
    border-radius: 3px;
    transition: .5s;
    object-fit: cover;
  }
  
  .wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  .hd-container{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .card-wrapper {
    /* height: 500px; */
    margin: 50px;
    box-shadow: 5px 5px 20px black;
    overflow: hidden;
    margin: 50px;
    position: relative;
  }
  
  .intro {
    height: 70px;
    width: 350px;
    padding: 6px;
    box-sizing: border-box;
    position: absolute;
    background: rgb(27, 27, 27, .5);
    color: white;
    transition: .5s;
    bottom: 20px;
    font-family: 'Poppins', sans-serif;
  }
  
  .heading{
    margin: 10px;
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 800;
  }
  
  .desc{
    font-size: 20px;
    margin: 10px;
    visibility: hidden;
    opacity: 0;
  }
  
  span{
    font-weight: bold;
  }
  
  .card-wrapper:hover {
    cursor: pointer;
  }
  
  .card-wrapper:hover .intro {
    height: 220px;
    bottom: 20px;
    background: black;
  }
  
  .card-wrapper:hover p{
    opacity: 1;
    visibility: visible;
  
  }
  
  .card-wrapper:hover .card-img-hd{
    transform: scale(1.1) rotate(-3deg);
  }